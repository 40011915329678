import { useMainStore } from "@/stores/main";
import { CheckoutVersion, FormulaPresentation, OrderRecipeABVersion, SectionIntroABVersion } from "@/utils/constants";

// ----- Checkout Version -----
export const initializeCheckoutVersion = (to: any) => {
  const checkoutVersionUrlQuery = to.query.checkout_version || "";
  if (to.query.contactId && to.query.checkout_version) return true; // * This are Abandoned Recipe Links and Checkout version will be initialized from version saved on HS on Contact level

  if (checkoutVersionUrlQuery) {
    setCheckoutVersion(checkoutVersionUrlQuery);
  } else {
    window.addEventListener("setCheckoutVersion", setCheckoutVersionListener);
  }
};

export const setCheckoutVersionListener = (event: CustomEvent<CheckoutVersion>) => {
  setCheckoutVersion(event.detail);
};

export const setCheckoutVersion = (version: CheckoutVersion) => {
  const mainStore = useMainStore();
  mainStore.setCheckoutVersion(version);
  return true;
};
// ----- Checkout Version -----

// ----- Formula Presentation Version -----
export const initializeFormulaPresentation = (to: any) => {
  const formulaPresentationUrlQuery = to.query.formula_presentation || "";
  if (to.query.contactId && to.query.formula_presentation) return true; // * This are Abandoned Recipe Links and Formula version will be initialized from version saved on HS on Contact level

  if (formulaPresentationUrlQuery && Object.values(FormulaPresentation).includes(formulaPresentationUrlQuery)) {
    setFormulaPresentation(formulaPresentationUrlQuery);
  } else {
    window.addEventListener("formula_presentation", setFormulaPresentationListener);
  }
};

export const setFormulaPresentationListener = (event: CustomEvent<FormulaPresentation>) => {
  setFormulaPresentation(event.detail);
};

export const setFormulaPresentation = (version: FormulaPresentation) => {
  const mainStore = useMainStore();
  mainStore.setFormulaPresentation(version);
  return true;
};
// -----  Formula Presentation Version -----

// ----- Order Recipe AB Version -----
export const initializeOrderRecipeABTest = (to: any) => {
  const orderRecipeABVersionQuery = to.query.order_recipe_version || "";
  if (to.query.contactId && to.query.order_recipe_version) return true; // * This are Abandoned Recipe Links and Formula version will be initialized from version saved on HS on Contact level

  if (orderRecipeABVersionQuery && Object.values(OrderRecipeABVersion).includes(orderRecipeABVersionQuery)) {
    setOrderRecipeABVersion(orderRecipeABVersionQuery);
  } else {
    window.addEventListener("order_recipe_ab_version", setOrderRecipeABVersionListener);
  }
};

export const setOrderRecipeABVersionListener = (event: CustomEvent<OrderRecipeABVersion>) => {
  setOrderRecipeABVersion(event.detail);
};

export const setOrderRecipeABVersion = (version: OrderRecipeABVersion) => {
  const mainStore = useMainStore();
  mainStore.setOrderRecipeABVersion(version);
  return true;
};
// -----  Order Recipe AB Version -----

// ----- Section Intro AB Version -----
export const initializeSectionIntroABTest = (to: any) => {
  const sectionIntroABVersionQuery = to.query.section_intro_version || "";
  if (to.query.contactId && to.query.section_intro_version) return true; // * This are Abandoned Recipe Links and Formula version will be initialized from version saved on HS on Contact level

  if (sectionIntroABVersionQuery && Object.values(SectionIntroABVersion).includes(sectionIntroABVersionQuery)) {
    setSectionIntroABVersion(sectionIntroABVersionQuery);
  } else {
    window.addEventListener("setSectionIntro", setSectionIntroABVersionListener);
  }
};

export const setSectionIntroABVersionListener = (event: CustomEvent<SectionIntroABVersion>) => {
  setSectionIntroABVersion(event.detail);
};

export const setSectionIntroABVersion = (version: SectionIntroABVersion) => {
  const mainStore = useMainStore();
  mainStore.setSectionIntroABVersion(version);
  return true;
};
// -----  Section Intro AB Version -----
