import { defineStore } from "pinia";
import { ref, computed } from "vue";
import {
  type Question,
  type QuestionAnswer,
  QuestionType,
  SubSectionKey,
  LifestyleQuestionKey,
  type QuestionTextPredictions,
  SectionKey,
  type QuestionRadio,
  type QuestionCheckbox,
  type QuestionYesNo,
  type QuestionText,
  type AnswerNumberList,
  type AnswerNumber,
  type AnswerText,
  type QuestionKey,
  WhyType,
} from "@/types/questions";
import type { Medication } from "@/types/apiTypes";
import { useQuestionsStore } from "@/stores/questions";
import { createQuestionRoute } from "@/router/guards";
import { RoutePath } from "@/router/routes";
import { findValueByIdInOptionsList } from "@/utils/functions";
import { allergiesOptions } from "@/utils/constants";

export type ActivityValue = "sedentary" | "lightly-active" | "moderately-active" | "very-active";
export type DietValue = "vegetarian" | "vegan" | "pescetarianism" | "no-diet";
export type AllergicValue = "allergic-b" | "allergic-c" | "allergic-d" | "allergic-iodine" | "none-above";
export type MedicalConditionValue = "cholesterol" | "hyperthyroidism" | "none-above";
export type SmokingValue = "yes" | "no";

const lifestyleQuestions: Question[] = [
  {
    key: LifestyleQuestionKey.ACTIVITY,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.whyWeAsk,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 75, value: "sedentary", phraseKey: "SEDENTARY" },
      { id: 76, value: "lightly-active", phraseKey: "LIGHTLY_ACTIVE" },
      { id: 77, value: "moderately-active", phraseKey: "MODERATELY_ACTIVE" },
      { id: 78, value: "very-active", phraseKey: "VERY_ACTIVE" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.MEAT,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 79, value: "3-times", phraseKey: "3_TIMES" },
      { id: 80, value: "1-2-times", phraseKey: "1_2_TIMES" },
      { id: 81, value: "never", phraseKey: "NEVER" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.FISH,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 82, value: "3-times", phraseKey: "3_TIMES" },
      { id: 83, value: "1-2-times", phraseKey: "1_2_TIMES" },
      { id: 84, value: "never", phraseKey: "NEVER" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.DIARY,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 85, value: "3-times-day", phraseKey: "3_TIMES_DAY" },
      { id: 86, value: "1-2-times-day", phraseKey: "1_2_TIMES_DAY" },
      { id: 87, value: "never", phraseKey: "NEVER" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.FRUIT,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 88, value: "3-servings", phraseKey: "3_SERVINGS" },
      { id: 89, value: "1-2-servings", phraseKey: "1_2_SERVINGS" },
      { id: 90, value: "not-daily", phraseKey: "NOT_DAILY" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.EGGS,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 91, value: "few-times", phraseKey: "FEW_TIMES" },
      { id: 92, value: "once-or-never", phraseKey: "ONCE_OR_NEVER" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.LEGUMES,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 93, value: "at-least-once", phraseKey: "AT_LEAST_ONCE" },
      { id: 94, value: "rarely", phraseKey: "RARELY" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.CAFFEINATE,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 110, value: "3-drinks", phraseKey: "3_DRINKS" },
      { id: 111, value: "1-2-drinks", phraseKey: "1_2_DRINKS" },
      { id: 112, value: "not-daily-or-never", phraseKey: "NOT_DAILY_OR_NEVER" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.DIET,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: false,
    hasHint: true,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 95, value: "vegetarian", phraseKey: "VEGETARIAN" },
      { id: 96, value: "vegan", phraseKey: "VEGAN" },
      { id: 97, value: "pescetarianism", phraseKey: "PESCETARIANISM" },
      { id: 98, value: "no-diet", phraseKey: "NO_DIET" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.ALLERGIES,
    active: true,
    type: QuestionType.CHECKBOX,
    hasWhyModal: false,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: allergiesOptions,
  } as QuestionCheckbox,
  {
    key: LifestyleQuestionKey.MEDS,
    active: true,
    type: QuestionType.TEXT_PRED,
    hasHint: false,
    hasWhyModal: true,
    whyType: WhyType.whyWeAsk,
    subSection: SubSectionKey.LIFESTYLE,
  } as QuestionTextPredictions,
  {
    key: LifestyleQuestionKey.MEDICAL_COND,
    active: true,
    type: QuestionType.CHECKBOX,
    hasWhyModal: true,
    whyType: WhyType.pleaseNote,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 104, value: "cholesterol", phraseKey: "CHOLESTEROL" },
      { id: 105, value: "hyperthyroidism", phraseKey: "HYPERTHYROIDISM" },
      { id: 106, value: "none-above", phraseKey: "NONE_ABOVE", negative: true },
    ],
  } as QuestionCheckbox,
  {
    key: LifestyleQuestionKey.CANCER,
    active: true,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.pleaseNote,
    hasHint: true,
    subSection: SubSectionKey.LIFESTYLE,
  } as QuestionYesNo,
  {
    key: LifestyleQuestionKey.SMOKE,
    active: true,
    type: QuestionType.RADIO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
    allValues: [
      { id: 107, value: "yes", phraseKey: "YES" },
      { id: 108, value: "sometimes", phraseKey: "SOMETIMES" },
      { id: 109, value: "no", phraseKey: "NO" },
    ],
  } as QuestionRadio,
  {
    key: LifestyleQuestionKey.FULL_NAME,
    active: true,
    type: QuestionType.TEXT,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.LIFESTYLE,
  } as QuestionText,
  {
    key: LifestyleQuestionKey.EMAIL,
    active: true,
    type: QuestionType.TEXT,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    hasHint: true,
    subSection: SubSectionKey.LIFESTYLE,
  } as QuestionText,
];

export const useLifestyleStore = defineStore(`lifestyle`, () => {
  const questions = ref(lifestyleQuestions);
  const answers = ref({
    "l-activity": { value: null } as AnswerNumber,
    "l-meat": { value: null } as AnswerNumber,
    "l-fish": { value: null } as AnswerNumber,
    "l-diary": { value: null } as AnswerNumber,
    "l-fruit": { value: null } as AnswerNumber,
    "l-eggs": { value: null } as AnswerNumber,
    "l-legumes": { value: null } as AnswerNumber,
    "l-diet": { value: null } as AnswerNumber,
    "l-allergic": { value: [] } as AnswerNumberList,
    "l-meds": { value: [] } as { value: Medication[] },
    "l-medical-cond": { value: [] } as AnswerNumberList,
    "l-cancer": { value: null } as AnswerNumber,
    "l-smoke": { value: null } as AnswerNumber,
    "l-caffeinate": { value: null } as AnswerNumber,
    "l-full-name": { value: "" } as AnswerText,
    "l-e-mail": { value: "" } as AnswerText,
  } as QuestionAnswer);
  const marketingConsentSelected = ref(false);
  const smsConsentSelected = ref(false);

  const activityAnswer = computed((): ActivityValue | "" => {
    const answerId = (answers.value[LifestyleQuestionKey.ACTIVITY] as AnswerNumber).value;
    if (!answerId) return "";
    const questActivity = questions.value.find((q) => q.key === LifestyleQuestionKey.ACTIVITY) as QuestionRadio;
    return findValueByIdInOptionsList(questActivity.allValues, answerId) as ActivityValue;
  });

  const dietAnswer = computed((): DietValue | "" => {
    const answerId = (answers.value[LifestyleQuestionKey.DIET] as AnswerNumber).value;
    if (!answerId) return "";
    const questDiet = questions.value.find((q) => q.key === LifestyleQuestionKey.DIET) as QuestionRadio;
    return findValueByIdInOptionsList(questDiet.allValues, answerId) as DietValue;
  });

  const allergiesAnswer = computed((): AllergicValue[] => {
    const answerIds: number[] = (answers.value[LifestyleQuestionKey.ALLERGIES] as AnswerNumberList).value;
    return answerIds.map((id) => findValueByIdInOptionsList(allergiesOptions, id) as AllergicValue);
  });

  const medicalConditionAnswer = computed((): MedicalConditionValue[] => {
    const answerIds: number[] = (answers.value[LifestyleQuestionKey.MEDICAL_COND] as AnswerNumberList).value;
    const questCond = questions.value.find((q) => q.key === LifestyleQuestionKey.MEDICAL_COND) as QuestionCheckbox;
    return answerIds.map((id) => findValueByIdInOptionsList(questCond.allValues, id) as MedicalConditionValue);
  });

  const smokingAnswer = computed((): SmokingValue => {
    const answerId = (answers.value[LifestyleQuestionKey.SMOKE] as AnswerNumber).value;
    if (!answerId) return "no";

    const questSmoke = questions.value.find((q) => q.key === LifestyleQuestionKey.SMOKE) as QuestionRadio;
    const value = findValueByIdInOptionsList(questSmoke.allValues, answerId);
    if (value === "yes") return "yes";
    if (value === "sometimes") return "yes";
    return "no";
  });

  const fullName = computed((): string => {
    return (answers.value[LifestyleQuestionKey.FULL_NAME] as AnswerText).value;
  });

  const email = computed((): string => {
    return (answers.value[LifestyleQuestionKey.EMAIL] as AnswerText).value;
  });

  const firstQuestionKey = computed((): QuestionKey => {
    return questions.value[0].key;
  });

  const updateAnswer = (cb: (answers: QuestionAnswer) => void) => {
    cb(answers.value);
  };

  const updateName = (name: string) => {
    answers.value[LifestyleQuestionKey.FULL_NAME].value = name;
  };

  const updateEmail = (email: string) => {
    answers.value[LifestyleQuestionKey.EMAIL].value = email;
  };

  const nextActiveQuestionKey = (activeQuestionKey: LifestyleQuestionKey): LifestyleQuestionKey => {
    const activeQIndex = questions.value.findIndex((question) => question.key === activeQuestionKey);
    return questions.value[activeQIndex + 1]?.key as LifestyleQuestionKey;
  };

  const goToNextStep = (): any => {
    const questStore = useQuestionsStore();
    if (questStore.activeQuestionKey === LifestyleQuestionKey.EMAIL) {
      return { name: RoutePath.LOADING };
    }
    const nextQuestionKey = nextActiveQuestionKey(questStore.activeQuestionKey as LifestyleQuestionKey);
    return createQuestionRoute(nextQuestionKey, SectionKey.LIFESTYLE);
  };

  const toggleActiveKey = (cb: (questions: Question[]) => void) => {
    cb(questions.value);
  };

  const toggleMarketingConsent = () => {
    marketingConsentSelected.value = !marketingConsentSelected.value;
  };

  const toggleSMSConsent = () => {
    smsConsentSelected.value = !smsConsentSelected.value;
  };

  return {
    questions,
    answers,
    marketingConsentSelected,
    smsConsentSelected,
    activityAnswer,
    dietAnswer,
    allergiesAnswer,
    medicalConditionAnswer,
    smokingAnswer,
    fullName,
    email,
    firstQuestionKey,
    updateAnswer,
    updateName,
    updateEmail,
    nextActiveQuestionKey,
    goToNextStep,
    toggleActiveKey,
    toggleMarketingConsent,
    toggleSMSConsent,
  };
});
